
import { Component } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import RefreshTokenService from '@/services/refreshTokenService';
import PrevLoginSelections from '@/helpers/prev-login-selection';
import { uniq } from 'lodash';

// types
import { Region } from '@/models/stores/regions';
import { Brand } from '@/models/stores/brands';
import { UserStore } from '@/models/users/userStore';

@Component({ name: 'RegionSelection' })
export default class RegionSelection extends LoggedInComponentBase {
  public selectedRegion: Region | null = Region.TAS;
  public selectedBrand: Brand | null = null;
  public isRegionSelectDisabled: boolean = false;
  public isRegionSelectVisible: boolean = false;

  public get stores(): UserStore[] {
    return this.storesModule.userStores;
  }

  public get regions(): Region[] {
    if (!this.userModule.currentUser) return [];
    const regionsFilteredBySelectedBrand = uniq(
      this.userModule
        .currentUser!.stores.filter(store => {
          if (this.selectedBrand === null) return true;
          return this.selectedBrand.name === store.brand.name;
        })
        .map(store => store.state)
    );

    if (regionsFilteredBySelectedBrand.length > 1) {
      regionsFilteredBySelectedBrand.push(Region.ALL);
      this.isRegionSelectDisabled = false;

      // check selection region is within brand
      if (this.selectedRegion !== null) {
        if (
          regionsFilteredBySelectedBrand.indexOf(this.selectedRegion) === -1
        ) {
          this.selectedRegion = null;
        }
      }
    } else {
      // select only option
      this.selectedRegion = regionsFilteredBySelectedBrand[0];
      this.isRegionSelectDisabled = true;
    }

    return regionsFilteredBySelectedBrand;
  }

  public get isBrandSelectVisible(): boolean {
    //return this.availableBrands.length > 1;
    return false;
  }

  public get availableBrands(): Brand[] {
    return this.storesModule.availableBrands;
  }

  public get selectedStore(): UserStore {
    return this.userModule.currentStore;
  }

  public set selectedStore(userStore) {
    this.userModule.setCurrentStore(userStore);
    this.storesModule.currentStoreSet(true);
  }

  public logout(): void {
    this.userModule.logout();
    RefreshTokenService.stopRefreshTokenTimeout();
    this.$emit('close');
  }

  public confirmSelection(): void {
    this.storesModule.regionChanged(this.selectedRegion!);
    this.storesModule.setCurrentBrand(this.selectedBrand!);
    this.$emit('loginCompleted');
  }

  created(): void {
    this.storesModule.getRegions();
    this.storesModule.getUserStores();
  }

  mounted() {
    this.setPreviousSelections();

    if (!this.isBrandSelectVisible) {
      // update selectedBrand with the only available brand
      this.selectedBrand = this.availableBrands[0];
    }
  }

  private setPreviousSelections() {
    const { prevRegion, prevBrandName } = PrevLoginSelections.getSelections();
    if (prevRegion && this.regions.includes(prevRegion)) {
      this.selectedRegion = prevRegion;
    }
    if (prevBrandName) {
      const matchedBrand: Brand | undefined = this.availableBrands.find(
        brand => brand.name === prevBrandName
      );
      if (matchedBrand) this.selectedBrand = matchedBrand;
    }
  }
}
