
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { UserModule, StoresModule } from '@/store';
import RefreshTokenService from '@/services/refreshTokenService';
import { uniqWith, isEqual } from 'lodash';
import RegionSelection from './regionSelection.vue';
import PrevLoginSelections from '@/helpers/prev-login-selection';

// types
import { LoginModel } from '../models/loginModel';
import { ValidationRules } from '../models/validationRules';
import { BooleanType } from '@/models/boolean';
import { LocalStorageKeys } from '@/constants';

const userModule = getModule(UserModule);
const storesModule = getModule(StoresModule);

@Component({
  components: { RegionSelection },
})
export default class LoginView extends Vue {
  public loginForm = true;
  public loginModel: LoginModel = new LoginModel();
  public rules: ValidationRules = new ValidationRules();
  public loginError = false;
  public loginSuccessful = false;
  private loginPending = false;
  public showRegionSelection = false;
  public isActive = false;

  public async login() {
    await this.executeLogin();
  }

  created() {
    // keep brand, region  before clear to ease next login
    PrevLoginSelections.storeSelections();

    // clears localstorage
    userModule.logout();

    PrevLoginSelections.setSelections();
  }

  mounted() {
    // This is to fix an issue with browser autofill that doesn't trigger the
    // active state of the inputs and results in username/password overlapping
    // the placeholder text.
    document
      .querySelector("[for='username']")
      ?.classList.add('v-label--active');
    document
      .querySelector("[for='password']")
      ?.classList.add('v-label--active');
  }

  private async executeLogin(): Promise<void> {
    this.loginPending = true;

    try {
      const result = await userModule.login({
        username: this.loginModel.userIdentifier,
        password: this.loginModel.password,
      });

      localStorage.setItem(LocalStorageKeys.TokenData, JSON.stringify(result));

      const user = await userModule.fetchUser({
        username: this.loginModel.userIdentifier,
        password: this.loginModel.password,
      });

      localStorage.setItem(LocalStorageKeys.User, JSON.stringify(user));

      const allStoresBrands = user.stores.map((store) => store.brand);
      const uniqBrands = uniqWith(allStoresBrands, isEqual);
      localStorage.setItem(
        LocalStorageKeys.AvailableBrands,
        JSON.stringify(uniqBrands)
      );
      storesModule.setAvailableBrands(uniqBrands);

      // Multi-store owners (MSO) will be prompt to select a region after logging in
      if (userModule.isMultiStoreOwner) {
        this.showRegionSelection = true;
      } else {
        this.loginSuccessful = true;
        userModule.setCurrentStore(user.stores[0]);
        storesModule.setCurrentRegion(user.stores[0].state);
        storesModule.setCurrentBrand(uniqBrands[0]);
      }
    } catch (err) {
      this.loginError = true;
    }
  }

  public get rossLoginLink(): string {
    return `${process.env.VUE_APP_API_ENDPOINT_PROXY}/sso/login`;
  }

  public onRegionSelectionClose() {
    this.showRegionSelection = false;
    this.loginModel.password = '';
  }

  @Watch('loginSuccessful')
  private async onLoginSucceeded(): Promise<void> {
    if (this.loginSuccessful) {
      this.loginError = false;
      this.loginPending = false;
      localStorage.setItem(LocalStorageKeys.LoginCompleted, BooleanType.True);
      RefreshTokenService.startRefreshTokenTimeout();

      // potential article redirect
      const articleId = this.$router.currentRoute.query?.article;
      if (typeof articleId === 'string') {
        await this.$router.push({
          name: 'helpAndFaqs.articleDetail',
          params: { id: articleId },
        });
      } else {
        await this.$router.push({ name: 'home.root' });
      }
    }
  }

  @Watch('loginPending')
  private onLoginPending(): void {
    if (this.loginPending) {
      this.loginError = false;
      this.loginSuccessful = false;
    }
  }

  @Watch('loginError')
  private onLoginError(): void {
    if (this.loginError) {
      this.loginPending = false;
      this.loginSuccessful = false;
    }
  }
}
